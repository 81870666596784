import { useTranslation } from "react-i18next";

const InConstruction = () => {
    const { t } = useTranslation();

    const images = ["./images/sustainable-container-aquaviva.png", "./images/solar-panel-roof.png", "./images/f-reg-pipeline-valves.png", "./images/cld.png"];

    return (<div style={{ width: '100vw', marginTop: '2em', marginRight: '2em', marginBottom: '2em', fontSize: '1.5em', color: '#000', display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginRight: '1em' }}>
            <img alt="" src="./images/Logo_SABosca_3D_vert-og.png" style={{ height: "150px", maxHeight: "250px" }} />
            <p style={{ whiteSpace: "pre-line", textAlign: "center" }}>{t("SiteUnderCons")}</p>
        </div>
        <div style={{ display: "flex", margin: "1em" }}>{images.map((image, k) => <img className="disable-visual-search" key={k} alt={image.substring(9, image.length - 3)} src={image} style={{ height: "15vw", width: "20vw", padding: ".1em" }} />)}</div>
    </div>);
}

export default InConstruction;