import { Outlet } from "react-router-dom";
import './App.css';
import Header from './Header.js';
import React from 'react';
import InConstruction from "./pages/InConstruction.js";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes
//  } from 'react-router-dom';

/*
  Potential page Color palette:

  #2D3142 Gunmetal
  #B0A6A6 Silver
  #FFFFFF White
  #514B4B Davy's Gray
  #4F5D75 Payne's Gray

  #D65108 Syracuse Red Orange
  #B0A6A6 Silver
  #FFFFFF White
  #514B4B Davy's Gray
  #F34213 Coquelicot

 */
const App = () => {

  document.title = "SABosca";

  return (
    <div className="App">
      <Header />
      <div id="App-content">
        <Outlet />
        <InConstruction />
      </div>
    </div>
  );
}



export default App;
