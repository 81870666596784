/* eslint-disable no-unused-vars */
import { useState } from "react";
import './Header.css';
import { Link } from 'react-router-dom';
import i18n from "./i18n";
import { useTranslation } from "react-i18next";
const Header = () => {

    return (<div id="header">
        <img id="header-background" src='./page_bkgd.jpg' alt="" />
        <div id='site-logo'>
            <Link to={"/"} rel="canonical" style={{ textDecoration: "none" }}><img id='site-logo-img' src='./logo_AD.png' alt="site-logo" /></Link>
        </div>
        <NavBar />
    </div>);
}

const NavBar = () => {
    const [selectedLang, setSelectedLang] = useState(i18n.language);
    const languages = [{ short: "en", name: "English" }, { short: "fr", name: "Français" }, { short: "pl", name: "Polski" }];
    const { t } = useTranslation();

    const chooseLanguage = (e) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);
        setSelectedLang(e.target.value);
        localStorage.setItem("lang", e.target.value);
    }
    // add a button for every page
    const btn1 = { id: "our-jobs-btn", value: t("Our jobs") }, // unused
        btn2 = { id: "cc-line-btn", value: t("CC Line") }, // unused
        btn3 = { id: "it-btn", value: t("IT") }, // unused
        btn4 = { id: "drones-btn", value: t("Drone Inspections") },
        btn5 = { id: "valves-btn", value: t("Valves") }; // unused
    const available = [btn1, btn2, btn3, btn4, btn5];

    return (
        <div id="navigation-bar">
            {available.map((b, i) => <Link key={i} className={b === btn1 || b === btn2 || b === btn3 || b === btn5
                ?
                "nav-btn btn-disabled"
                :
                "nav-btn"} to={'/drones'} id={b.id} type="submit">{b.value}</Link>)}
            {/* {<input id="our-jobs-btn" className="nav-btn" type="submit" value={btn1} />}
            {<input id="cc-line-btn" className="nav-btn" type="submit" value={btn2} />}
            {<input id="it-btn" className="nav-btn" type="submit" value={btn3} />}
            {<input id="drones-btn" className="nav-btn" type="submit" value={btn4} />} */}
            <select defaultValue={selectedLang} onChange={chooseLanguage}>{languages.map((lang, k) => <option key={k} value={lang.short}>{lang.name}</option>)}</select>
        </div>);
}

export default Header;